import React from 'react'
import { css } from 'glamor'

const Row = ({children, style, onClick}) => {

  return (
    <tr {...css(
      { '&:hover': {
          backgroundColor:'#f5f5f5bf',
          cursor:'pointer'
        },
        ...style

      })} onClick={onClick} style={{borderBottom: '1px solid rgba(102 51 153 / 10%)'}}>
      {children}
    </tr>

  )
}

export default Row


