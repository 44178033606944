import React, {useState, useEffect, Fragment} from "react"
import Button from '../components/Button'
import gcpLogo from '../images/gcp.png'
import azLogo from '../images/az.png'
import awsLogo from '../images/aws.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { css } from 'glamor';
import {useKindeAuth} from '@kinde-oss/kinde-auth-react';
import nodeLogo from '../images/node-logo.png'
import ax from 'axios'
import { SpinnerCircularFixed } from 'spinners-react';
import colors from '../components/_colors'
import DeployFunctionModal from '../components/modals/DeployFunctionModal'
import Table from '../components/Table'
import Row from '../components/Row'
import { useNavigate } from "react-router-dom";

const FunctionRow = ({ func }) => {
  const history = useNavigate()
  return (
    <Row>
      <td><input type="checkbox"/></td>
      <td style={{padding:'0px 8px 0px 0px'}}><a href="https://compute.polyserve.io/14kgfskdgmk23mk2m34">{func._id}</a></td>
      <td style={{padding:'0px 8px 0px 0px'}}>
        <div style={{display:'flex'}}>
          <img style={{height:'20px', marginRight:'5px'}} src={nodeLogo}/>
          <div style={{backgroundColor:'#eeeeee', borderRadius:'5px',textAlign:"center"}}>HTTP</div>
        </div>
      </td>
      <td style={{padding:'0px 8px 0px 0px'}}>{func.name}</td>
      <td style={{padding:'0px 8px 0px 0px'}}>
        <div style={{display:'flex'}}>
          {func.clouds.map((x) => (
            <>
              {(x.type === 'azure-function') && (
                <img src={azLogo} style={{height:'20px'}}/>
              )}

              {(x.type === 'aws-lambda') && (
                <img src={awsLogo} style={{height:'20px'}}/>
              )}
              {(x.type === 'gcp-function') && (
                <img src={gcpLogo} style={{height:'20px'}}/>
              )}
            </>
          ))}

        </div>
      </td>

      <td style={{padding:'0px 8px 0px 0px'}}>
        <div style={{display:'flex'}}>
          {func.locations.map((location) => (
            <>
              {(location === "uk") && (
                "🇬🇧"
              )}
              {(location === "jp") && (
                "🇯🇵"
              )}
              {(location === "za") && (
                "🇿🇦"
              )}
              {(location === "au") && (
                "🇦🇺"
              )}
              {(location === "us") && (
                "🇺🇸"
              )}
              {(location === "in") && (
                "🇮🇳"
              )}
            </>
          ))}

        </div>
      </td>

      <td style={{padding:'0px 8px 0px 0px', display:'flex', alignItems:'center'}}>
        <Button onClick={() => history(`/logs?functionId=${func._id}`)} style={{margin:'2px', height:'20px', minHeight:0, marginLeft:'auto !important',marginRight:'10px'}}textStyle={{fontSize:'12px'}} title="Logs"/>
        <FontAwesomeIcon style={{marginLeft:'20px'}} icon={faEllipsis}/>

      </td>
      </Row>

  )
}

const Functions = ({stripeSession, loadingSubscription, subscribed}) => {
  const { isAuthenticated, getToken } = useKindeAuth();

  const [functions, setFunctions] = useState([])
  const [loadingFunctions, setLoadingFunctions] = useState(true)
  const [deployModalOpen, setDeployModalOpen] = useState(false)
  const { user, isLoading } = useKindeAuth();

  const closeModal = () => {
    setDeployModalOpen(false)
  }


  useEffect(() => {
    if (deployModalOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'scroll'
    }

  }, [deployModalOpen])

  useEffect(() => {
    if (isAuthenticated) {
      const getFunctions = async () => {
        setLoadingFunctions(true)
        try {
          const token = await getToken();
          console.log(token);
          const res = await ax.get(`${process.env.REACT_APP_CORE_API}/functions`,{
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          setLoadingFunctions(false)
          setFunctions(res.data)
          console.log(res)
        } catch(e) {
          console.log(e)
        }
      }
      getFunctions()
    }
  },[])
  return (
    <Fragment>
      {deployModalOpen && (
        <DeployFunctionModal close={closeModal}/>
      )}
      <div>
        <div style={{display:'flex',width:'100%'}}>
          <h1 style={{marginTop:0}}>Functions</h1>
          <Button disabled={loadingFunctions || (functions.length >= 3 && !subscribed) } onClick={() => setDeployModalOpen(true)} style={{margin:'0 0 0 auto'}} title="+ Deploy function"/>
        </div>
          <Table
            loading={loadingFunctions}
            ths={[
              <th style={{textAlign:'left', width:'50px'}}>{ <input type="checkbox"/>}</th>,
              <th style={{textAlign:'left', width:'230px'}}>ID</th>,
              <th style={{textAlign:'left', width:'80px'}}>TYPE</th>,
              <th style={{textAlign:'left', width:'200px'}}>NAME</th>,
              <th style={{textAlign:'left', width:'120px'}}>PROVIDERS</th>,
              <th style={{textAlign:'left', width:'120px'}}>LOCATIONS</th>,
              <th style={{textAlign:'left', width:'100%'}}></th>,
            ]}
          trs={functions.map((func) => <FunctionRow func={func}/>)}
          />
        {(functions.length <= 0 && !loadingFunctions) && <p style={{textAlign:'center'}}>No functions yet</p>}
      </div>
      {((!subscribed) && !loadingSubscription && !loadingFunctions) && (
        <div {...css({
          'animation': 'fadeIn 0.5s'
        })} style={{width:'50%', marginLeft:'auto',marginRight:'auto',marginTop:'150px', border: `1px solid ${colors.lightPurple}`, padding:'20px', textAlign:'center'}}>
          {3 - functions.length} free functions remaining - subscribe for unlimited
          <Button onClick={() => {

            window.location.href = `${process.env.REACT_APP_STRIPE_SUBSCRIBE}?prefilled_email=${user.preferred_email}&client_reference_id=${user.id.split(':')[1]}`;
          }} title="Subscribe"/>
          <p style={{fontSize:'12px'}}>$15/month + usage</p>
        </div>
      )}

    </Fragment>
  )
}

export default Functions
