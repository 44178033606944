import React from 'react';
import { css } from 'glamor';
import { SpinnerCircularFixed } from 'spinners-react';

import colors from './_colors';
import "@fontsource/space-mono"

function Button({
  loading,
  onClick,
  title,
  style,
  textStyle,
  mouseOver,
  mouseLeave,
  icon,
  lowercase,
  to,
  type,
  disabled,
  fullWidth,
}) {
  const defaultStyles = {
    width: fullWidth ? '100%' : 'auto',
    border: 'none',
    paddingRight: '24px',
    paddingLeft: '24px',
    fontWeight: '700',
    fontFamily: 'Space Mono',
    display: 'flex',
    background:
      style && style.background ? style.background : colors.darkPurple,
    marginTop: 10,
    maxWidth: '340px',
    marginBottom: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    cursor: !disabled ? 'pointer' : 'initial',
    boxShadow: `0px 2px 0px ${colors.lightPurple}`,
    borderRadius: '5px',
    position: 'relative',
    zIndex: '1',
    opacity: loading || disabled ? 0.6 : 1,
    minHeight: '38px',
    height: '38px',
    // '&:hover': {
    //   background:
    //     !loading && !disabled
    //       ? style && style.backgroundHover
    //         ? style.backgroundHover
    //         : colors.lightPurple
    //       : colors.darkPurple,
    // },
    // '&:hover p': {
    //   color: textStyle && textStyle.hoverColor ? textStyle.hoverColor : 'white',
    // },
  };

  const defaultTextStyle = {
    margin: 'auto',
    fontSize: '18px',
    display: 'inherit',
    color: 'white',
  };

  const s = { ...defaultStyles, ...style };
  const ts = { ...defaultTextStyle, ...textStyle };

 /* const history = useHistory();
  const push = (to) => {
    if (history) {
      history.push(to);
    } else {
      window.location.href = to;
    }
  };*/

  return (
    <button
      type="submit"
      style={{ outline: 0 }}
      onClick={(e) => {
        // this says let us use disabled buttons even if they're disabled in dev or local
        // eslint-disable-next-line no-unused-expressions
        (process.env.ENV !== 'local' || process.env.ENV !== 'development' || !disabled) &&
          onClick && onClick(e);
        // if (to && !disabled) push(to);
      }}
      {...css(s)}
    >
      <div style={{ display: 'inherit', margin: 'auto' }}>
        {loading && (
          <SpinnerCircularFixed size={22} color='grey' speed={200} style={{marginRight:'10px'}}/>
        )}
        {icon && typeof icon === 'string' ? (
          <img alt="Icon" src={icon} style={{ marginRight: '6px' }} />
        ) : (
          icon
        )}
        <p {...css(ts)}>
          {title && (
            <>
              {!lowercase && title.toString()}{' '}
              {lowercase && title}
            </>
          )}
        </p>
      </div>
    </button>
  );
}

export default Button;
