const colors = {
  darkGreen: '#00A959',
  lightGreen: '#8CC84B',
  mutedGreen: '#86B9A4',
  gradientGreen: 'linear-gradient(90deg, #77CA2A 0%, #00AB51 100%)',
  gradientGreenVertical: 'linear-gradient(180deg, #77CA2A 0%, #00AB51 100%)',
  darkPurple: '#663399',
  midPurple: 'rgba(183, 143, 186, 0.22)',
  lightPurple: 'rgba(102, 51, 153, 0.1)',
  lightBlue: '#E8F5FF',
  greyBlue: '#DCEAF5',
  wowRed: '#FD4C34',
  mutedRed: '#E38472',
  pink: '#FFB4C9',
  yellow: '#FBC700',
  lightOrange: '#FE9E17',
  darkOrange: '#FF8236',
  cream: '#FFEFE0',
  white: '#FFFFFF',
  darkBlue: '#4661cd',
  shadow: 'rgba(161, 183, 201, 0.3)',
  darkShadow: 'rgba(0, 0, 0, .4)',
};

export default colors;
