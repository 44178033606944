import React from 'react';
import { css } from 'glamor';
import colors from './_colors';
import imgDownArrow from '../assets/img_keyboard_arrow_down.png';

// eslint-disable-next-line react/prop-types
const Select = ({
  styles,
  placeholder,
  onChange,
  onBlur,
  error,
  type,
  options,
  value,
  bg,
  noEndAdorn,
  disabled,
  carat,
}) => {
  const defaultStyles = {
    height: '64px',
    minHeight: '36px',
    width: '64px',
    display: 'block',
    marginBottom: '10px',
    outline: 'none',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '5px',
    fontSize: '18px',
    lineHeight: '22px',
    padding: '8px',
    color: 'rgba(255, 255, 255, 0.8)',
    '::placeholder': {
      color: 'rgba(255, 255, 255, .8)',
    },
    appearance: 'none',
  };

  const errorStyles = {
    border: `2px solid ${colors.wowRed}`,
  };

  const lightStyles = {
    background: `url(${imgDownArrow}), ${colors.white}`,
    backgroundRepeat: 'no-repeat, repeat',
    backgroundPosition: 'right .7em top 50%, 0 0',
    backgroundSize: '.65em auto, 100%',
    color: '#65617D',
    '::placeholder': {
      color: '#65617D',
    },
    ':-webkit-autofill': {
      boxShadow: '0 0 0 30px #E8F5FF inset',
      color: '#65617D !important',
    },
  };

  let s;

  if (bg === 'light') {
    s = { ...defaultStyles, ...lightStyles, ...styles };
  } else {
    s = { ...defaultStyles, ...styles };
  }

  if (noEndAdorn) {
    const hideAdorn = {
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      textIndent: '1px',
      textOverflow: '',
    };
    s = { ...s, ...hideAdorn };
  }

  if (error) {
    s = { ...s, ...errorStyles };
  }

  return (
    <select
      disabled={disabled}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      {...css(s)}
      placeholder={placeholder}
      type={type || 'text'}
    >
      {options}
    </select>
  );
};

export default Select;
