import React from 'react';
import { css } from 'glamor';
import colors from './_colors';
import imgDownArrow from '../assets/img_keyboard_arrow_down.png';

// eslint-disable-next-line react/prop-types
const Select = ({
  styles,
  placeholder,
  onChange,
  onBlur,
  error,
  type,
  options,
  value,
  bg,
  noEndAdorn,
  disabled,
  carat,
  label
}) => {
  const defaultStyles = {
    display:'flex',
    flexWrap:'wrap',
    fontSize:'14px',
  };


  let s;

    s = { ...defaultStyles, ...styles };

  return (
    <>
      {label && (
        <p style={{marginBottom:5, fontSize:'14px'}}>{label}</p>
      )}
    <div
      disabled={disabled}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      {...css(s)}
      placeholder={placeholder}
      type={type || 'text'}
    >

      {options && options.map(option => (
        <div {...css({
          ':hover' :{
            cursor:!option.props.disabled? 'pointer': 'default'
          }
        })} onClick={() => console.log(option.props.value)} style={{opacity: option.props.disabled ? '0.3' :'1', backgroundColor: option.props.selected ? 'rgba(0,0,0,0.08)':'rgba(0,0,0,0.03)', width:'auto', padding:'5px',border:'1px solid rgba(0,0,0,0.08)', whiteSpace:'nowrap'}}>{option}</div>
      ))}
    </div>
      </>
  );
};

export default Select;
