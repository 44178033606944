import React from 'react';
import { css } from 'glamor';
import colors from './_colors';

// eslint-disable-next-line react/prop-types
const Input = ({
  styles,
  onClick,
  placeholder,
  onChange,
  onBlur,
  error,
  disableErrorMessage,
  type,
  value,
  bg,
  autoFocus,
  onFocus,
  onKeyDown,
  disabled,
  errorSetter,
  label
}) => {
  const defaultStyles = {
    height: '30px',
    width: '100%',
    display: 'block',
    marginBottom: '10px',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    outline: 'none',
    border: '1px solid #eeebf1',
    boxSizing: 'border-box',
    borderRadius: '5px',
    fontSize: '14px',
    fontFamily: 'Space Mono',
    backgroundColor: colors.white,
    lineHeight: '22px',
    padding: '8px',
    color: disabled ? '#e5e3f3' : 'black',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .1)',
    },
    ':focus': {
      border: `1px solid ${colors.darkPurple}`,
    },
    ':-webkit-autofill': {
      boxShadow: '0 0 0 30px #FFFFFF inset',
    },
  };

  const lightStyles = {
    background: '#E8F5FF',
    color: disabled ? '#e5e3f3' : '#65617D',
    '::placeholder': {
      color: '#d7eaf5',
    },
    ':-webkit-autofill': {
      boxShadow: '0 0 0 30px #E8F5FF inset',
      color: '#65617D !important',
    },
  };

  const errorStyles = {
    border: `2px solid ${colors.wowRed}`,
  };

  let s;

  if (bg === 'light') {
    s = { ...defaultStyles, ...lightStyles, ...styles };
  } else {
    s = { ...defaultStyles, ...styles };
  }

  if (error) {
    s = { ...s, ...errorStyles };
  }
  /* eslint-disable jsx-a11y/no-autofocus */
  return (
    <>
      {label && (
        <p style={{marginBottom:5, fontSize:'14px'}}>{label}</p>
      )}
      <input
        disabled={disabled}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
        onFocus={onFocus}
        onClick={onClick}
        value={value}
        onBlur={onBlur}
        onChange={(e) => {
          if (onChange) onChange(e);
          if ((error || disableErrorMessage) && errorSetter) {
            errorSetter(null);
          }
        }}
        {...css(s)}
        placeholder={placeholder}
        type={type || 'text'}
      />
      {error && !disableErrorMessage && (
        <p
          style={{
            fontSize: '15px',
            lineHeight: '14px',
            marginTop: '-2px',
            color: colors.wowRed,
          }}
        >
          {error}
        </p>
      )}
    </>
  );
};

export default Input;
