import React from 'react';
import { css } from 'glamor';
import colors from './_colors';
import imgDownArrow from '../assets/img_keyboard_arrow_down.png';
import ActionOutside from './ActionOutside'

// eslint-disable-next-line react/prop-types
const Modal = ({
  styles,
  bg,
  title,
  children,
  actions,
  close
}) => {
  const defaultStyles = {
    height: '36px',
    minHeight: '36px',
    width: '340px',
    display: 'block',
    marginBottom: '10px',
    outline: 'none',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '5px',
    fontSize: '18px',
    lineHeight: '22px',
    padding: '8px',
    color: 'rgba(255, 255, 255, 0.8)',
    '::placeholder': {
      color: 'rgba(255, 255, 255, .8)',
    },
    appearance: 'none',
  };

  const errorStyles = {
    border: `2px solid ${colors.wowRed}`,
  };

  const lightStyles = {
    background: `url(${imgDownArrow}), ${colors.lightBlue}`,
    backgroundColor: colors.lightBlue,
    backgroundRepeat: 'no-repeat, repeat',
    backgroundPosition: 'right .7em top 50%, 0 0',
    backgroundSize: '.65em auto, 100%',
    color: '#65617D',
    '::placeholder': {
      color: '#65617D',
    },
    ':-webkit-autofill': {
      boxShadow: '0 0 0 30px #E8F5FF inset',
      color: '#65617D !important',
    },
  };

  let s;

  if (bg === 'light') {
    s = { ...defaultStyles, ...lightStyles, ...styles };
  } else {
    s = { ...defaultStyles, ...styles };
  }

  return (
    <div style={{
      width:'100vw',
      height:'100vh',
      position:'fixed',
      backgroundColor:'rgba(193,201,210,.7)',
      top:0,
      left:0,
      zIndex:999999,
      display:'flex'
    }}>
        <ActionOutside close={close} style={{
          width:'448px',
          height:'80%',
          backgroundColor:'white',
          marginLeft:'auto',
          marginRight:'auto',
          borderRadius:'6px',
          border: '1px solid #eeebf1',
          alignSelf:'center',
          boxShadow: '0 2px 15px rgb(94 84 103 / 25%)',
          display:'flex',
          flexDirection: 'column'
        }}>

          <div style={{padding:'20px', borderBottom:'1px solid rgba(0,0,0,0.08)', flex: '0 1 auto'}}>
            {title}
          </div>
          <div style={{padding:'5px 20px 20px 20px', overflow:'scroll', flex: '1 1 auto'}}>
            {children}
          </div>
          <div style={{padding:'20px', borderTop:'1px solid rgba(0,0,0,0.08)', display:'flex' }}>
            {actions.map(action => (
              <>
                {action}
              </>
            ))}
          </div>
          </ActionOutside>
    </div>
  );
};

export default Modal;
