import React, {useState, useEffect} from "react"
import "@fontsource/space-mono"
import Pusher from 'pusher-js'
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { sublime } from '@uiw/codemirror-theme-sublime';
import { defaultKeymap } from '@codemirror/commands';
import { keymap } from "@codemirror/view";
import Button from './components/Button'
import ax from 'axios';
import awsLogo from './images/aws.png'
import azLogo from './images/az.png'
import gcpLogo from './images/gcp.png'
import logo from './images/logo192.png'
import FunctionLogs from './components/FunctionLogs'
import {useKindeAuth} from '@kinde-oss/kinde-auth-react';
import SelectFlag from './components/SelectFlag'
import { css } from 'glamor'
import { SpinnerCircularFixed } from 'spinners-react';

import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
  Link,
} from "react-router-dom";
import Functions from './pages/Functions'
import Modal from './components/Modal'
import Input from './components/Input'
import GridSelect from './components/GridSelect'
import Logs from './pages/Logs'
import colors from './components/_colors'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "Space Mono, -apple-system, Roboto, sans-serif, serif",
  maxWidth:'1200px',
  marginLeft:'auto',
  marginRight:'auto'
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 32,
}
const headingAccentStyles = {
  color: "#663399",
}
// markup
const IndexPage = () => {
  const [deploying, setDeploying] = useState(false);
  const [functionUrls, setFunctionUrls] = useState([])
  const [logs, setLogs] = useState([])
  const [push, setPush] = useState(null);
  const [loc, setLoc] = useState('uk')
  const [provAz, setProvAz] = useState(true)
  const [provAws, setProvAws] = useState(true)
  const [provGcp, setProvGcp] = useState(true)
  const [functionId, setFunctionId] = useState(null);
  const [postDeployChanges, setPostDeployChanges] = useState(false)
  const [isMac, setIsMac] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const { user, isAuthenticated, isLoading,login, register, getToken, logout } = useKindeAuth();
  const [stripeSession, setStripeSession] = useState(null)
  const [subscribed, setSubscribed] = useState(null)
  const [loadingAccount, setLoadingAccount] = useState(false)
  const [loadingSubscription, setLoadingSubscription] = useState(true)

  //const gradient = new Gradient()

  useEffect(() => {
    const x = getIsMac()
    setIsMac(x)
  }, [])
  //gradient.initGradient('#gradient-canvas')

  const getAccountLink = async () => {
    try {
      setLoadingSubscription(true)
      const token = await getToken();
      console.log(token);
      const x = await ax.get(`${process.env.REACT_APP_CORE_API}/account?returnTo=${encodeURI(window.location.href)}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setLoadingSubscription(false)

      if (x.data.session.url) {
        setStripeSession(x.data.session)
        setSubscribed(x.data.user.subscribed)
      }
      return x
    } catch(e) {
      console.log(e)
      setLoadingSubscription(false)
    }
  }
  useEffect(() => {
    if (isAuthenticated) {
      getAccountLink()
    }
  },[isAuthenticated])

  useEffect(() => {
    if (loc.includes('za')) {
      setProvGcp(false)
    }
  },[loc])

  const [code, setCode] = useState('module.exports = async (context) => {\n' +
    '  console.log(`Hello world from ${process.env.POLYSERVE_ENVIRONMENT}`)\n' +
    '  const response = {\n' +
    '    statusCode: 200,\n' +
    '    body: context,\n' +
    '  };\n' +
    '  return response;\n' +
    '};')



  const deployNewFunction = async () => {
    if (deploying) return false;
    setDeploying(true)
    console.log(code)
    let clouds = []
    if (provAz) clouds.push('az')
    if (provAws) clouds.push('aws')
    if (provGcp) clouds.push('gcp')

    try {

      const token = await getToken();
      console.log(token);

      const res= await await toast.promise(ax.post(`${process.env.REACT_APP_CORE_API}/deploy/function`, {
        code: code,
        providers: clouds,
        location: loc
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }), {
        pending:'Deploying Function',
        success: 'Function Deployed',
        error: {
          render({data}) {
            return data.response.data.message
          }
        }
      })
      setDeploying(false)
      setFunctionUrls([...functionUrls,res.data.functionUrl])
      setFunctionId(res.data.id)
      console.log(res)
    } catch(e) {
      console.log(e)
      setDeploying(false)
    }
    }

    const getIsMac = () => {
      return window.navigator.appVersion.indexOf('Mac') !== -1;
  }

  const updateFunction = async () => {
    if (functionId && !deploying) {
      setDeploying(true)
      console.log(code)
      let clouds = []
      if (provAz) clouds.push('az')
      if (provAws) clouds.push('aws')
      if (provGcp) clouds.push('gcp')

      try {
        const res = await ax.put(`${process.env.REACT_APP_CORE_API}/deploy/function/${functionId}`, {
          code: code,
          providers: clouds,
          location: loc
        })
        setDeploying(false)
        setFunctionUrls([res.data.functionUrl])
        setFunctionId(res.data.id)
        setPostDeployChanges(false)
        console.log(res)

      } catch (e) {
        console.log(e)
      }
    }
  }



  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {(isLoading || loadingAccount) && (
        <div style={{display: 'flex', flexDirection:'column',height:'100vh',justifyContent:'center'}}>
          <img style={{width:'80px', marginLeft:'auto', marginRight:'auto'}} src={logo}/>
          <SpinnerCircularFixed size={22} secondaryColor={'rgba(102 51 153 / 10%)'} color={colors.darkPurple} speed={200} style={{marginRight:'auto',marginLeft:'auto',marginTop:'15px', marginBottom:'200px'}}/>
        </div>
      )}
      {(isAuthenticated && !loadingAccount) && (
        <>
          {/*<canvas style={{position:'fixed', zIndex:'-1', height:'56px'}} id="gradient-canvas" data-transition-in />*/}
          <div style={{height:'30px',color:'#535353', backgroundColor: 'rgba(102 51 153 / 10%)',padding:'5px', paddingLeft:'40px',paddingRight:'40px', display:'flex',borderTop:'1px solid #ebeef1',borderBottom:'1px solid #ebeef1', alignItems:'center'}}>
            <img style={{height:'30px',marginRight:'10px'}} src={logo}/>
            polyserve
            <div style={{marginLeft:'auto',marginRight:'20px', display:'flex', alignItems: 'center'}}>
              <a href="https://polyserve.notion.site/Polyserve-Roadmap-701f320a6fd74cfd9f5f588ab86b63e9">
                Roadmap
              </a>
              {(stripeSession && !loadingSubscription) && (
                <p{...css({
                  ':hover' :{
                    cursor:'pointer'
                  }
                })} onClick={async () => {
                  setLoadingAccount(true)
                  if (isAuthenticated) {
                    const str = await getAccountLink()
                    window.location.href = str.data.session.url;
                  }
                }}>
                  <FontAwesomeIcon style={{marginLeft:'20px'}} icon={faUser}/>
                </p>
              )}

              <p {...css({
                ':hover' :{
                  cursor:'pointer'
                }
              })} onClick={() => logout()}>
                <FontAwesomeIcon style={{marginLeft:'20px'}} icon={faRightFromBracket}/>
              </p>

            </div>
          </div>
          <div style={{display:'flex',paddingLeft:'40px'}}>
              <div style={{margin: '8px'}}>
                <Link to="/functions">functions</Link>
              </div>
            <div style={{margin: '8px'}}>
              <Link to="/logs">logs</Link>
            </div>
              <div style={{margin: '8px', color:'#0000001c'}}>
                storage
              </div>

          </div>

          <div style={{padding:'10px 96px'}}>
              <Routes>
                {isAuthenticated && (
                  <Route
                    path="/"
                    element={<Navigate to="/functions" replace />}
                  />
                )}
                <Route path="/functions" element={ <Functions loadingSubscription={loadingSubscription} subscribed={subscribed} stripeSession={stripeSession}/>}/>
                <Route path="/logs" element={ <Logs/>}/>
              </Routes>

            </div>

        </>
      )}
    <main style={pageStyles}>
      {(!isAuthenticated && !isLoading && !loadingAccount) && (
        <>
          <title>polyserve</title>
          <div style={{display:'flex',width:'100%'}}>
            <img src={logo} style={{height: '80px'}}/>
            <div style={{display:'flex', marginRight:0,marginLeft:'auto'}}>
              <a href="https://www.notion.so/polyserve/Polyserve-Roadmap-701f320a6fd74cfd9f5f588ab86b63e9">Roadmap</a>
              {isLoading || !isAuthenticated && (
                <Button onClick={login} style={{marginRight:0, marginTop:0, marginLeft:'20px'}} title="Login"/>
              )}
            </div>


          </div>
          <h1 style={headingStyles}>
            polyserve - <span style={{fontSize:'18px'}}>the serverless multicloud</span>

            <br />
            <a style={{textDecoration:'none'}} href="mailto:hello@polyserve.io"><span style={headingAccentStyles}>hello@polyserve.io</span></a>
          </h1>
          <p style={{maxWidth:'600px'}}>We are the platform that simplifys and multifys cloud development and infrastructure. We let you focus on creating differentiating software.  </p>
          <div>
            <div style={{marginBottom:'16px', marginTop:'32px'}}>
              <img onClick={()=> setProvAz(!provAz)} style={{height:'40px',paddingRight:'10px', opacity: provAz ? 1 :0.5}} src={azLogo}/>
              <img onClick={()=> setProvAws(!provAws)} style={{height:'40px',paddingRight:'10px',opacity: provAws ? 1 :0.5}} src={awsLogo}/>
              <img onClick={()=> {
                if (loc !== 'za') {
                  setProvGcp(!provGcp)
                }
              }} style={{height:'40px',opacity: provGcp ? 1 :0.5}} src={gcpLogo}/>
            </div>


            <CodeMirror
              value={code}
              extensions={[javascript({ jsx: false }),
                keymap.of([
                  {
                    key: "Mod-s",
                    preventDefault: true,
                    run: async () => {
                      await updateFunction()
                      return true;
                    }
                  },
                  ...defaultKeymap
                ])
              ]}
              theme={sublime}
              onChange={(v) => {
                setCode(v)
                if (functionId) {
                  setPostDeployChanges(true)
                }
              }}

            />
            {functionId && (
              <Button disabled={!postDeployChanges} style={{maxWidth:'none', marginLeft:0}} onClick={() => updateFunction()} loading={deploying} title={`Save (${isMac ? 'cmd' : 'ctrl'}-s)`}/>
            )}
            {!functionId && (
              <div style={{display:'flex'}}>
                <SelectFlag onChange={(l) => setLoc(l.target.value)} bg='light' options={[
                  <option value='uk'>🇬🇧</option>,
                  <option value='us'>🇺🇸</option>,
                  <option value='jp'>🇯🇵</option>,
                  <option value='au'>🇦🇺</option>,
                  <option value='in'>🇮🇳</option>,
                  <option value='za'>🇿🇦</option>,
                ]} value={loc}/>
                <Button style={{maxWidth:'none', marginLeft:0}} onClick={() => deployNewFunction()} loading={deploying} title="Deploy Multicloud Function"/>
              </div>
            )}
          </div>

          <>
            {functionUrls.map((url) => (
              <div style={{backgroundColor:'rgba(183,143,186,0.22)', padding:'20px'}}>
                <p>🔗 Give it a try! (might take a minute to propogate to all providers)</p>
                <a href={url}>{url}</a>
                <p style={{fontSize:'12px'}}>Keep refreshing!</p>
              </div>
            ))}


            {functionId && (
              <>
                <FunctionLogs functionIds={[functionId]}/>
              </>
            )}

          </>
        </>
      )}


    </main>
    </>
  )
}

export default IndexPage
