import React, {useState, useEffect} from "react"
import "@fontsource/space-mono"
import Pusher from 'pusher-js'
import awsLogo from '../images/aws.png'
import azLogo from '../images/az.png'
import gcpLogo from '../images/gcp.png'
import CodeMirror from '@uiw/react-codemirror';
import { sublime } from '@uiw/codemirror-theme-sublime';
import { javascript } from '@codemirror/lang-javascript';
import { css } from 'glamor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import colors from './_colors'
const TrialLogRow = ({ log }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <>
      {log.messages && (
        <>
        <tr {...css({
          ':hover' :{
            cursor:'pointer'
          }
        })} onClick={() => setExpanded(!expanded)} style={{display:'flex', alignItems: 'center'}}>
            <div style={{minWidth:'50px', display:'flex', placeContent:'center',marginRight:'20px', marginLeft:'20px'}}>
              {log.cloud === 'azure' && <img style={{height:'20px'}} src={azLogo}/>}
              {log.cloud === 'aws' && <img style={{height:'20px'}} src={awsLogo}/>}
              {log.cloud === 'gcp' && <img style={{height:'20px'}} src={gcpLogo}/>}
            </div>
            <p style={{overflow:'hidden',whiteSpace:'nowrap'}}><span style={{color: log.type === 'log'? 'green': 'black', marginRight:'30px'}}>{log.type.toUpperCase()}</span><span>{log.messages ? log.messages.map(m => (m)): ''}</span></p>
            <FontAwesomeIcon style={{marginLeft:'auto',marginRight:'5px', paddingLeft:'18px', color: colors.darkPurple}} icon={expanded ? faCaretDown : faCaretLeft}/>
        </tr>
          {expanded && (
            <tr style={{ padding: '20px', display:'flex'}} colSpan="100%">
              <CodeMirror style={{width:'100%'}} extensions={[javascript({ jsx: false })]} theme={sublime} editable={false} width="100%" value={log.messages.join(" ")}/>
            </tr>
          )}
        </>
      )}
    </>
  )
}

// markup
const FunctionLogs = ({functionIds}) => {
  const [logs, setLogs] = useState([])

  useEffect(() => {
    const pusher = new Pusher("4a08dc07ae2cb88d38f4", {
      cluster: "eu",
      encrypted: true
    })

    functionIds.forEach((functionId) => {
      const channel = pusher.subscribe(functionId)
      channel.bind_global((eventType, data) => {
        console.log(data)
        if (data.messages) {
          setLogs(logs => [{type: eventType, messages: data.messages, cloud: data.cloud},...logs])
        }
      });
    })

    return () => {
      functionIds.forEach((functionId) => {
        console.log("unsubbed")
        pusher.unsubscribe(functionId)
      })
    }
  },[functionIds])


  return (
    <main>
      {logs.length <= 0 && (
        <p>Logs will appear here...</p>
      )}
      <div style={{backgroundColor:'#00000005'}}>
        {logs.map((log) => (
          <>
            <TrialLogRow log={log}/>
          </>
        ))}
      </div>
    </main>
  )
}

export default FunctionLogs
