import React, {useState} from 'react'
import Modal from '../Modal'
import Button from '../Button'
import GridSelect from '../GridSelect'
import nodeLogo from '../../images/node-logo.png'
import pythonLogo from '../../images/python.png'
import gcpLogo from '../../images/gcp.png'
import awsLogo from '../../images/aws.png'
import azLogo from '../../images/az.png'
import Input from '../Input'
import {useKindeAuth} from '@kinde-oss/kinde-auth-react';
import ax from 'axios'

const DeployFunctionModal = ({ close }) => {

  const [providers, setProviders] = useState(['aws','gcp','az'])
  const [locations, setLocations] = useState(['uk'])
  const [name, setName] = useState("")
  const [url, setUrl] = useState("")
  const { getToken } = useKindeAuth();
  const [deploying, setDeploying] = useState(false)
  const [urlError,setUrlError] = useState(false)

  const deployNewFunction = async () => {
    if (deploying) return false;
    setDeploying(true)
    let clouds = []
    if (providers.includes('az')) clouds.push('az')
    if (providers.includes('aws')) clouds.push('aws')
    if (providers.includes('gcp')) clouds.push('gcp')

    try {

      const token = await getToken();
      console.log(token);
      const res= await ax.post(`${process.env.REACT_APP_CORE_API}/deploy/function`, {
        providers: clouds,
        location: locations[0],
        url,
        name
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setDeploying(false)
      // setFunctionUrls([...functionUrls,res.data.functionUrl])
      // setFunctionId(res.data.id)
      console.log(res)
    } catch(e) {
      console.log(e)
    }
  }

  const toggleArrayItem = async(array, setter, item) => {
    if(array.includes(item)){
      const newArray = array.filter(x => x !== item)
      setter(newArray)
    } else {
      setter([...array,item])
    }
  }
  return (
    <Modal title="Deploy a function"
           actions={[
             <Button onClick={close} style={{height:'28px', minHeight:0, backgroundColor: 'grey', marginLeft:'auto', marginRight:0}} textStyle={{fontSize: '14px'}} title="Cancel"/>,
             <Button loading={deploying} onClick={deployNewFunction} style={{height:'28px', minHeight:0, marginLeft:'8px', marginRight:0}} textStyle={{fontSize: '14px'}} title="Deploy function"/>
           ]}
           close={close}
    >
      <GridSelect
        label="Trigger"
        options={[
          <div selected style={{display:'flex'}}>
            HTTP
          </div>,
          <div disabled style={{display:'flex'}}>
            Timer
          </div>,
        ]}></GridSelect>
      <GridSelect
        label="Runtime"
        options={[
          <div selected style={{display:'flex'}}>
            <img src={nodeLogo} style={{height:'22px',marginRight:'5px'}}/>
            node
          </div>,
          <div disabled style={{display:'flex'}}>
            <img src={pythonLogo} style={{height:'22px',marginRight:'5px'}}/>
            python
          </div>,
        ]}></GridSelect>
      <GridSelect
        label="Provider/s"
        options={[
          <div disabled={locations.includes('za')} onClick={() => toggleArrayItem(providers,setProviders,"gcp")} selected={providers.includes("gcp")} value="gcp" style={{display:'flex'}}>
            <img src={gcpLogo} style={{height:'22px',marginRight:'5px'}}/>
            Google Cloud Platform
          </div>,
          <div onClick={() => toggleArrayItem(providers,setProviders,"aws")} selected={providers.includes("aws")} value="aws" style={{display:'flex'}}>
            <img src={awsLogo} style={{height:'22px',marginRight:'5px'}}/>
            Amazon Web Services
          </div>,
          <div onClick={() => toggleArrayItem(providers,setProviders,"az")} selected={providers.includes("az")} value="az" style={{display:'flex'}}>
            <img src={azLogo} style={{height:'22px',marginRight:'5px'}}/>
            Microsoft Azure
          </div>
        ]}></GridSelect>

      <GridSelect
        label="Location (multiple coming soon)"
        options={[
          <div selected={locations.includes("uk")} onClick={() => setLocations(['uk'])} value="uk" style={{display:'flex'}}>
            🇬🇧 United Kingdom
          </div>,
          <div selected={locations.includes("us")} onClick={() => setLocations(['us'])} value="us" style={{display:'flex'}}>
            🇺🇸 United States
          </div>,
          <div selected={locations.includes("jp")} onClick={() => setLocations(['jp'])} value="jp" style={{display:'flex'}}>
            🇯🇵 Japan
          </div>,
          <div selected={locations.includes("au")}  onClick={() => setLocations(['au'])} value="au" style={{display:'flex'}}>
            🇦🇺 Australia
          </div>,
          <div selected={locations.includes("in")}  onClick={() => setLocations(['in'])}value="in" style={{display:'flex'}}>
            🇮🇳 India
          </div>,
          <div selected={locations.includes("za")} onClick={() => {
            setLocations(['za'])
            if (providers.includes('gcp')) {
              toggleArrayItem(providers,setProviders,'gcp')
            }
          } } value="za" style={{display:'flex'}}>
            🇿🇦 South Africa
          </div>
        ]}></GridSelect>
      <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="my-multicloud-function" label="Name"/>
      <Input value={url} onChange={(e) => setUrl(e.target.value)} placeholder="https://pathtozippackage.com" label="Zip URL*" />
    </Modal>
  )
}

export default DeployFunctionModal

