import React, {useState, useEffect, Fragment} from "react"
import Button from '../components/Button'
import gcpLogo from '../images/gcp.png'
import azLogo from '../images/az.png'
import awsLogo from '../images/aws.png'
import {useKindeAuth} from '@kinde-oss/kinde-auth-react';
import ax from 'axios'
import DeployFunctionModal from '../components/modals/DeployFunctionModal'
import Table from '../components/Table'
import Pusher from 'pusher-js'
import Row from "../components/Row"
import CodeMirror from '@uiw/react-codemirror';
import { sublime } from '@uiw/codemirror-theme-sublime';
import { javascript } from '@codemirror/lang-javascript';
import { useSearchParams } from "react-router-dom";

const LogRow = ({ log }) => {
  if (log.data) log = log.data
  const [expanded, setExpanded] = useState(false)
  const getLogColor = (logType) => {
    if(logType === 'log') return 'green'
    if(logType === 'info') return 'blue'
    if(logType === 'warn') return 'yellow'
    if(logType === 'error') return 'red'
    return 'white'
  }
  return (
    <>
    <Row onClick={() => setExpanded(!expanded)}>
      <input type="checkbox"/>
      <td style={{padding:'0px 12px 0px 0px', whiteSpace:'nowrap', width:'40px'}}>{new Date(log.logtime).getDate()}/{new Date(log.logtime).getMonth()} {new Date(log.logtime).toLocaleTimeString()}</td>
      <td style={{padding:'0px 12px 0px 0px', width: '130px'}}>
        <td style={{display:'inline', width:'50px'}}>
          {(log.location === "uk") && (
            "🇬🇧"
          )}
          {(log.location === "jp") && (
            "🇯🇵"
          )}
          {(log.location === "za") && (
            "🇿🇦"
          )}
          {(log.location === "au") && (
            "🇦🇺"
          )}
          {(log.location === "us") && (
            "🇺🇸"
          )}
          {(log.location === "in") && (
            "🇮🇳"
          )}
        </td>
      </td>
      <td style={{padding:'0px 12px 0px 0px', textAlign:'center', width:'250px'}}>

        <td style={{display:'inline',width:'50px'}}>
          {log.cloud === 'azure' && <img style={{height:'20px'}} src={azLogo}/>}
          {log.cloud === 'aws' && <img style={{height:'20px'}} src={awsLogo}/>}
          {log.cloud === 'gcp' && <img style={{height:'20px'}} src={gcpLogo}/>}
        </td>

      </td>
      <td style={{padding:'0px 12px 0px 0px'}}>
        {log.functionId}
      </td>
      <td style={{color: getLogColor(log.type),padding:'0px 12px 0px 0px'}}>
        {log.type ? log.type.toUpperCase() : ""}
      </td>
      <td col style={{padding:'0px 12px 0px 0px', width:'100%',whiteSpace:'nowrap', overflow:'hidden'}}>{log.messages ? log.messages.map(m => (m)): ''}</td>
      </Row>
      {log.messages && log.messages.length > 0 && expanded && (
        <Row style={{ verticalAlign:'top', width:'100%', '&:hover': { cursor:'initial'}}}>
          <td style={{padding:'20px'}} colspan="100%">
            <CodeMirror extensions={[javascript({ jsx: false })]} theme={sublime} editable={false} width="100%" value={log.messages.join(" ")}/>
          </td>
        </Row>
      )}

      </>
  )
}

const Logs = () => {
  const { isAuthenticated, getToken } = useKindeAuth();

  const [logs, setLogs] = useState([])
  const [functionIds, setFunctionIds] = useState([])
  const [loadingLogs, setLoadingLogs] = useState(true)
  const [deployModalOpen, setDeployModalOpen] = useState(false)
  const [params] = useSearchParams()

  useEffect(() => {
    const pusher = new Pusher("4a08dc07ae2cb88d38f4", {
      cluster: "eu",
      encrypted: true
    })

    functionIds.forEach((functionId) => {
      const channel = pusher.subscribe(functionId)
      console.log(`bound to channel ${functionId}`)
      channel.bind_global((eventType, data) => {
        if (data.messages) {
          console.log(data)
          setLogs(logs => [{logtime: data.logtime, functionId: data.functionId, location: data.location,type: eventType, messages: data.messages, cloud: data.cloud},...logs])
        }
      });
    })

    return () => {
      functionIds.forEach((functionId) => {
        console.log("unsubbed")
        pusher.unsubscribe(functionId)
      })
    }
  },[functionIds])

  const closeModal = () => {
    setDeployModalOpen(false)
  }

  useEffect(() => {
    if (isAuthenticated) {
      const getLogs = async () => {
        console.log("calling get logs")
        try {
          const token = await getToken();
          let url = `${process.env.REACT_APP_CORE_API}/logs`
          console.log(params.get('functionId'))
          if (params.get('functionId')) url+= `?functionId=${params.get('functionId')}`
          const res = await ax.get(url,{
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          if (!params.get('functionId')) {
            const idRes = await ax.get(`${process.env.REACT_APP_CORE_API}/functions?$select=_id`,{
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
            const idArray = idRes.data.map(i => i._id)
            console.log(idArray)
            setFunctionIds(idArray)
          } else {
            setFunctionIds([params.get('functionId')])
          }

          setLoadingLogs(false)
          if (res.data.docs.length > 0) {
            setLogs(res.data.docs)
            console.log(res.data.docs)
          }
          console.log(res)
        } catch(e) {
          console.log(e)
        }
      }
      getLogs()
    }
  },[])
  return (
    <Fragment>
      <div>
        <div style={{display:'flex',width:'100%'}}>
          <h1 style={{marginTop:0}}>Logs</h1>
        </div>
          <Table
            loading={loadingLogs}
            ths={[
              <th style={{textAlign:'left', width:'50px'}}>{ <input type="checkbox"/>}</th>,
              <th style={{textAlign:'left', width:'130px'}}>TIME</th>,
              <th style={{textAlign:'left', width:'50px'}}></th>,
              <th style={{textAlign:'left', width:'50px'}}></th>,
              <th style={{textAlign:'left', width:'230px'}}>FUNCTION</th>,
              <th style={{textAlign:'left', width:'50px'}}></th>,
              <th style={{textAlign:'left', width:'100%'}}>CONTENT</th>,
          ]}
            trs={logs.length > 0 ? logs.map((log) => <LogRow log={log} />) : []}
            />
        {(logs.length <= 0 && !loadingLogs) && <p style={{textAlign:'center'}}>No logs yet</p>}
      </div>

    </Fragment>
  )
}

export default Logs
