import React, {useState, useEffect, Fragment} from "react"
import Button from '../components/Button'
import gcpLogo from '../images/gcp.png'
import azLogo from '../images/az.png'
import awsLogo from '../images/aws.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { css } from 'glamor';
import {useKindeAuth} from '@kinde-oss/kinde-auth-react';
import nodeLogo from '../images/node-logo.png'
import ax from 'axios'
import { SpinnerCircularFixed } from 'spinners-react';
import colors from '../components/_colors'
import DeployFunctionModal from '../components/modals/DeployFunctionModal'

const Table = ({ths, trs, loading, style}) => {

  return (
    <Fragment>
      <div style={{maxHeight:'512px', borderTop:'1px solid rgba(102 51 153 / 10%)',overflowY:'scroll'}}>
        <table style={{width:'100%', borderCollapse: 'collapse', fontSize:'14px',tableLayout:'fixed'}}>
          <tr style={{ position:'sticky',top:0,background: 'white', zIndex:'9999'}}>
            {ths.map((th) => (
              th
            ))}
          </tr>

          {trs.map(tr => (
            tr
          ))}


        </table>
        {loading && (
          <div style={{width:'100%', display:'flex', marginTop:'30px'}}>
            <SpinnerCircularFixed size={22} secondaryColor={'rgba(102 51 153 / 10%)'} color={colors.darkPurple} speed={200} style={{marginRight:'auto',marginLeft:'auto'}}/>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default Table
